import { redirectToLoginAsBidder } from '~coreUtils/redirect-to-bidder-login';

export const useELKRKRoute: () => any = () => {
  const runtimeConfig = useRuntimeConfig();

  const previousRoute = () => {
    if (import.meta.client) {
      const previousRouteData = sessionStorage.getItem('redirectToAfterLogin');
      return previousRouteData ? JSON.parse(previousRouteData) : null;
    }
    return false;
  };

  const routeItemId = (route: any) => {
    return route.params.itemId.split('?');
  };

  const setLocalPath = () => {
    return import.meta.client ? window.location.origin : runtimeConfig.public.appUrl;
  };

  return {
    previousRoute,
    routeItemId,
    redirectToLoginAsBidder,
    setLocalPath
  };
};
